import { initializeApp } from 'firebase/app';
import { getFirestore } from '@firebase/firestore';
import { getStorage } from '@firebase/storage';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyAiiuFlgmeo7HKPKwy_KtNhG9BDP1fQo9U',
  authDomain: 'rfmloyaltyco-7ddf2.firebaseapp.com',
  projectId: 'rfmloyaltyco-7ddf2',
  storageBucket: 'rfmloyaltyco-7ddf2.appspot.com',
  messagingSenderId: '95588283237',
  appId: '1:95588283237:web:c65b402678bc14135d678c',
  measurementId: 'G-JEQBWFKF48'
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const auth = getAuth(app);
