import React, { useEffect, useState, useRef, useCallback } from 'react';
import { 
    Grow,
    Paper,
    Popper,
    MenuItem,
    MenuList,
    ClickAwayListener,
    Tooltip,
    Badge,
    IconButton,
    SvgIcon,
    Typography,
} from '@material-ui/core';
import { Bell as NotifyIcon } from 'react-feather';
import { makeStyles } from '@material-ui/core/styles';
import {db} from 'src/services/firestoreInstance'
import { onSnapshot, doc, updateDoc, getDoc } from 'firebase/firestore';
import { useHistory } from 'react-router';
import { useSnackbar } from 'notistack';
import NotificationSound from './static/notification_alert.mp3'


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  badge: {
    height: 10,
    width: 10,
    borderRadius: 5,
    marginTop: 10,
    marginRight: 5
  },
  popover: {
    width: 300,
    height: 200,
    overflow: 'hidden',
    overflowY: 'scroll'
  },
  menuItem: {
    padding: theme.spacing(2),
  }
}));

export default function MenuListComposition() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const anchorRef = React.useRef(null);
  const [Notifications, setNotifications] = useState(null)
  const [newMsg, setNewMsg] = useState(false)
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const audioPlayer = useRef(null);

  const getNotifications = useCallback(() => {

    const docRef = doc(db, 'notifications', 'i3lN6QFrcRoEoIGEF58M')

    onSnapshot(docRef, async (data) => {

      let notify = data.data()

      setNewMsg(notify.newNotification)

      const list = notify.notifications.reverse()

      setNotifications(list)

      const initial = localStorage.getItem('initial')

      console.log(initial)

      if(initial === 'true'){

        if(notify.newNotification){

          if(list[0]['type'] === 'Complaint'){
  
            audioPlayer.current.play();
            enqueueSnackbar(list[0]['message'] , {
              variant: 'success'
            });
  
          }else{
  
            const docRef = doc(db, 'users', list[0]['user_id'])
  
            let user = await getDoc(docRef)
  
            user = user.data()
  
            audioPlayer.current.play();
            enqueueSnackbar(`New Message Received From ${user.user_name}`, {
              variant: 'success'
            });
          }
        }

      }else{
        localStorage.setItem('initial', true)
      }
    })
      
    },[enqueueSnackbar],)

  useEffect(() => {
    localStorage.setItem('initial', false)
    getNotifications()
  }, [getNotifications])
  

  const handleToggle = async () => {

    setOpen((prevOpen) => !prevOpen);

    const docRef = doc(db, 'notifications', 'i3lN6QFrcRoEoIGEF58M')

    await updateDoc(docRef, {
      newNotification: false
    })
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleNavigation = (type, id) => {
    if(type === 'Complaint'){
        history.push('/app/management/complaints')
        setOpen(false);
    }else{
        const url = '/app/management/chats/' + id
        history.push(url)
        setOpen(false);
    }
  }

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div className={classes.root}>
      <div>
        {/* <Button
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          Toggle Menu Grow
        </Button> */}
        <Tooltip title="Notifications">
            <Badge
                color="secondary"
                variant={newMsg ? "dot" : "none"}
                classes={{ badge: classes.badge }}
            >
                <IconButton 
                    color="inherit"
                    ref={anchorRef}
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                >
                    <SvgIcon fontSize="small">
                        <NotifyIcon />
                    </SvgIcon>
                </IconButton>
            </Badge>
        </Tooltip>
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper className={classes.popover}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                    {
                        Notifications?.map(notify => (
                            <MenuItem style={{display: 'grid'}} key={notify?.user_id} className={classes.menuItem} onClick={() => {handleNavigation(notify?.type, notify?.user_id)}} value={notify?.message}>
                                <Typography>{notify?.message}</Typography>
                                <Typography style={{fontSize: '13px'}}><span style={{color: 'grey'}}>sent at: </span>{notify?.createdAt.toDate().toDateString()}</Typography>
                            </MenuItem>
                        ))
                    }
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        <audio ref={audioPlayer} src={NotificationSound} />
      </div>
    </div>
  );
}
